import React from 'react';
import Loadable from 'react-loadable';

const LoadableComponent = Loadable({
  loader: () => import('./App'),
  loading: () => null,
});

function LoadableApp(props) {
  return <LoadableComponent {...props} />;
}

export default LoadableApp;
