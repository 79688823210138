(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ADSK_FEYNMAN_CORE"), require("ADSK_REACT_AXIOS"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ADSK_FEYNMAN_CORE", "ADSK_REACT_AXIOS", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["ADSK_ACCOUNT_PORTAL_APP_USER_ACCESS"] = factory(require("React"), require("ADSK_FEYNMAN_CORE"), require("ADSK_REACT_AXIOS"), require("ReactDOM"));
	else
		root["ADSK_ACCOUNT_PORTAL_APP_USER_ACCESS"] = factory(root["React"], root["ADSK_FEYNMAN_CORE"], root["ADSK_REACT_AXIOS"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__) {
return 